<template>
  <div class="k-9sptci" v-loading="loading">
    <el-form
      :inline="true"
      class="k-9sptci-header"
      :model="searchForm"
      ref="searchForm"
    >
      <el-form-item label="UID" prop="id">
        <el-input placeholder="UID" v-model="searchForm.id"></el-input>
      </el-form-item>
      <el-form-item label="角色昵称" prop="name">
        <el-input placeholder="角色昵称" v-model="searchForm.name"></el-input>
      </el-form-item>

      <el-form-item label="当前状态" prop="status">
        <el-select v-model="searchForm.status" clearable placeholder="请选择">
          <!-- <el-option value="0" label="全部"></el-option> -->
          <el-option value="1" label="正常"></el-option>
          <el-option value="0" label="回收站"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="success" @click="searchClick">查询</el-button>
      </el-form-item>
      <perContainer :perm="`armory:${type}:save`">
        <el-form-item>
          <el-button type="primary" @click="add">新增角色</el-button>
        </el-form-item>
      </perContainer>
      <perContainer :perm="`armory:${type}:save`">
        <el-form-item>
          <el-button type="primary" @click="exportTeamToExcel"
            >导出数据</el-button
          >
        </el-form-item>
      </perContainer>
      <perContainer :perm="`armory:${type}:save`">
        <el-form-item>
          <el-button type="primary" @click="dialogVisible = true"
            >官方微信</el-button
          >
        </el-form-item>
      </perContainer>
    </el-form>
    <el-table :data="dataTable" style="margin-top:20px" border>
      <template v-for="item in dataList">
        <el-table-column
          v-if="item.prop == 'avatar'"
          :key="item._id"
          :prop="item.prop"
          :type="item.type"
          :label="item.label"
          :width="item.width"
          :formatter="item.formatter"
        >
          <template slot-scope="scope">
            <img
              v-if="scope.row.avatar && scope.row.avatar.length > 0"
              style="border-radius: 15px; width: 30px; height: 30px"
              :src="scope.row.avatar"
            />
            <div v-else style="color: #777777; width: 30px; text-align: center">
              暂无
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-else-if="item.prop == 'title'"
          :key="item._id"
          :prop="item.prop"
          :type="item.type"
          :label="item.label"
          :width="item.width"
          :formatter="item.formatter"
        >
          <template slot-scope="scope">
            <img
              v-if="scope.row.title && scope.row.title._id"
              style="width: 90px; height: 30px"
              :src="scope.row.title.img"
            />
            <div v-else style="color: #777777; width: 90px; text-align: center">
              暂无
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-else
          :key="item._id"
          :prop="item.prop"
          :type="item.type"
          :label="item.label"
          :width="item.width"
          :formatter="item.formatter"
        >
        </el-table-column>
      </template>
      <el-table-column label="操作" fixed="right">
        <template slot-scope="scope">
          <!-- <perButton
            :perm="`armory:${type}:list`"
            @click.native.stop="handlePreview(scope.row)"
            >预览</perButton
          > -->
          <perButton
            :perm="`armory:${type}:save`"
            @click.native.stop="handleEdit(scope.row)"
            >编辑裁判</perButton
          >
          <!-- <perButton
            :perm="`armory:${type}:changerank`"
            @click.native.stop="handleEditPoints(scope.row)"
            >编辑积分</perButton
          > -->
          <popconfirmButton
            v-if="scope.row.status === 1"
            :perm="`armory:${type}:save`"
            content="是否确认删除？"
            text="删除"
            @onConfirm="handleDelete(scope.row)"
            style="margin-left: 10px"
          ></popconfirmButton>
          <popconfirmButton
            v-else
            :perm="`armory:${type}:save`"
            content="是否确认恢复？"
            text="恢复"
            @onConfirm="handleRecovery(scope.row)"
            style="margin-left: 10px"
          ></popconfirmButton>
        </template>
      </el-table-column>
    </el-table>
    <div class="k-9sptci-footer">
      <el-pagination
        @current-change="handleQuery"
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="pageSize"
        :current-page.sync="pager.pageNum"
        layout="sizes, prev, pager, next"
        :total="pager.total"
      >
      </el-pagination>
    </div>

    <integralRuleManager
      @onClose="integralRuleVisible = false"
      v-if="integralRuleVisible"
      :type="type"
    ></integralRuleManager>

    <integralEdit
      v-if="integralVisible"
      from="info"
      :subject_name="integralData.subject_name"
      :ref_id="integralData.ref_id"
      :season_id="integralData.season_id"
      @onClose="integralVisible = false"
    ></integralEdit>
    <preview
      v-if="previewVisible"
      :ref_id="previewRefId"
      @closed="onPreviewClosed"
    ></preview>
    <!-- 微信弹框 -->
    <el-dialog
      :visible.sync="dialogVisible"
      title="上传裁判认证微信"
      width="30%"
    >
      <el-upload
        class="dataForm_upload"
        :show-file-list="false"
        :before-upload="beforeTopImageUpload"
        action="#"
        drag
        :http-request="httpUploadRequest"
      >
        <img v-if="avatar" :src="avatar" class="avatar" />
        <div v-else>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">拖到此处，或<em>点击上传</em></div>
        </div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisibleYes()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 新增角色弹框 -->
    <el-dialog :visible.sync="adddialogVisible" title="新增角色" width="30%">
      <el-form :model="form" :rules="rules" ref="form">
        <el-row
          :gutter="20"
          style="margin-bottom:20px; align-items: center;line-height: 32px;"
        >
          <!-- 游戏UID -->
          <el-col :span="8">
            <label for="gameUid">
              <span style="color : red">*</span> 游戏UID：</label
            >
          </el-col>
          <el-col :span="16">
            <el-form-item prop="gameUid" required>
              <el-input
                id="gameUid"
                placeholder="请输入游戏UID"
                v-model="form.gameUid"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="align-items: center; line-height: 32px;">
          <!-- 角色昵称 -->
          <el-col :span="8">
            <label for="nickname">
              <span style="color : red">*</span> 角色昵称：</label
            >
          </el-col>
          <el-col :span="16">
            <el-form-item prop="nickname" required>
              <el-input
                id="nickname"
                placeholder="请输入角色昵称"
                v-model="form.nickname"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="adddialogVisibleNo">取 消</el-button>
        <el-button type="primary" @click="adddialogVisibleYes">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import perButton from '@/components/perm/perButton'
import integralRuleManager from '../../armory/components/integralRuleManager.vue'
import integralEdit from '../../armory/referee/integralEdit'
import { uploadOssUrl } from '@/utils/uploadUrl'
import { randomStr } from '@/utils/randomStr'
import preview from '../../armory/referee/preview'
export default {
  name: 'referee_list',
  components: {
    perButton,
    integralEdit,
    integralRuleManager,
    preview,
  },
  props: {},
  data() {
    let self = this
    return {
      form: {
        gameUid: '',
        nickname: '',
      },
      rules: {
        gameUid: [
          { required: true, message: '请输入游戏UID', trigger: 'blur' },
        ],
        nickname: [
          { required: true, message: '请输入角色昵称', trigger: 'blur' },
        ],
      },
      avatar: '',
      adddialogVisible: false, // 新增
      dialogVisible: false, // 微信
      ids: [],
      type: 'referee',
      loading: false,
      previewVisible: false,
      previewRefId: '',
      pageSize: 10,
      pager: {
        total: 0,
        pageNum: 1,
      },
      searchForm: {
        id: '',
        name: '',
        status: '',
      },
      dataTable: [],
      dataList: [
        {
          prop: 'uid',
          label: 'UID',
          width: 300,
        },
        {
          prop: 'name',
          label: '角色昵称',
          width: 300,
        },
        // {
        //   prop: 'avatar',
        //   label: '裁判头像',
        //   width: 200,
        // },
        {
          prop: 'status',
          label: '状态',
          width: 300,
          formatter: (row) => {
            switch (row.status) {
              case 1:
                return '正常'
              case 0:
                return '回收站'
              default:
                return ''
            }
          },
        },
      ],
      integralRuleVisible: false,
      integralVisible: false,
      integralData: {
        ref_id: '',
        subject_name: '',
        season_id: '',
      },
    }
  },
  computed: {},
  methods: {
    onPreviewClosed() {
      this.previewVisible = false
      this.previewRefId = ''
    },
    // 导出数据
    async exportTeamToExcel() {
      this.$http.download({
        url: '/admin/management/referee/export_TeamToExcel',
        method: 'post',
      })
    },
    // 新增角色
    add() {
      this.adddialogVisible = true
    },
    adddialogVisibleYes() {
      //  this.adddialogVisible = false
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          // 表单验证通过
          const res = await this.$http.armory.officialsave({
            status: 1,
            uid: this.form.gameUid,
            name: this.form.nickname,
          })
          if (res.code === 0) {
            this.$message.success('新增成功')
            this.handleQuery()
          }
          this.$refs.form.resetFields()
          this.adddialogVisible = false // 关闭对话框
        } else {
          // 表单验证失败
          console.log('表单验证失败')
          return false
        }
      })
    },
    adddialogVisibleNo() {
      this.$refs.form.resetFields()
      this.adddialogVisible = false // 关闭对话框
    },
    // 官方微信
    async dialogVisibleYes() {
      const res = await this.$http.armory.saveWx({
        type: 1,
        imgAddress: this.avatar,
      })
      if (res.code === 0) {
        this.$message.success('上传成功')
      }
      this.dialogVisible = false
    },
    beforeTopImageUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    async httpUploadRequest(item) {
      this.loading = true
      let fileName = randomStr() + '.jpg'
      let path = uploadOssUrl().sect + fileName
      let result = await this.$http.uploadFile(path, fileName, item.file)
      console.log('🚀 ~ httpUploadRequest ~ result:', result)
      if (result) {
        this.avatar = result
      } else {
        this.$message.error('上传失败')
      }
      this.loading = false
    },
    //
    async handleEdit(row) {
      this.handleQuery()
      this.$router.push({
        path: '/operate/refereeCertification/add',
        query: { _id: row._id, uid: row.uid, name: row.name },
      })
    },
    //  删除
    async handleDelete(row) {
      this.loading = true
      let { data, errorCode } = await this.$http.armory.officiaListrecovery({
        _id: row._id,
        status: 0,
      })
      this.loading = false
      if (errorCode != '0000') {
        return
      }
      this.$message({
        type: 'success',
        message: '删除成功',
      })
      this.handleQuery()
    },
    // 恢复
    async handleRecovery(row) {
      this.loading = true
      let { data, errorCode } = await this.$http.armory.officiaListrecovery({
        _id: row._id,
        status: 1,
      })
      this.loading = false
      if (errorCode != '0000') {
        return
      }
      this.$message({
        type: 'success',
        message: '恢复成功',
      })
      this.handleQuery()
    },
    // 查询
    searchClick() {
      this.$refs.searchForm.validate((v) => {})
      this.pager.pageNum = 1
      this.handleQuery()
    },
    async handleSizeChange(val) {
      this.pageSize = val
      this.pager.pageNum = 1
      this.pager.total = 0
      this.handleQuery()
    },
    async handleQuery() {
      const { pageNum } = this.pager
      const { data, errorCode } = await this.$http.armory.officiaList({
        uid: this.searchForm.id,
        name: this.searchForm.name,
        status: this.searchForm.status,
        pageNum,
        pageSize: this.pageSize,
      })
      if (errorCode != '0000') return
      this.dataTable = data.data
      this.pager = data.pager
    },
    onRefereeChanged(data) {
      let current = this.dataTable.find((r) => r._id == data._id)
      if (current) {
        current.uid = data.uid
        current.name = data.name
        current.avatar = data.avatar
        current.title = data.title
        current.status = data.status
      } else {
        this.pager.pageNum = 1
        this.handleQuery()
      }
    },
    ...mapActions('tabs', [
      'handleTabsEdit',
      'addTabs',
      'handleTabsClick',
      'removeTab',
      'removeTabByPath',
    ]),
  },
  async created() {
    this.handleQuery()
    // 拿微信二维码
    const res = await this.$http.armory.officialDetail({
      type: 1,
    })
    if (res && res.data && res.data.imgAddress) {
      this.avatar = res.data.imgAddress
    }

    this.$bus.$off('onRefereeChanged', this.onRefereeChanged)
    this.$bus.$on('onRefereeChanged', this.onRefereeChanged)
  },
  destroyed() {
    this.$bus.$off('onRefereeChanged', this.onRefereeChanged)
  },
}
</script>
<style lang="less" scoped>
.k-9sptci-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  .el-form-item {
    margin-bottom: 0;
  }
}
.k-9sptci-footer {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}
/deep/ .dialog-footer {
  display: flex;
  justify-content: center;
}

/deep/ .dataForm_upload {
  width: 178px;
  height: 178px;
}
/deep/ .el-dialog__header {
  display: flex;
  justify-content: center;
}
/deep/ .el-dialog__body {
  display: flex;
  justify-content: center;
}
/deep/ .el-upload {
  width: 178px;
  height: 178px;
}
/deep/ .el-upload-dragger {
  width: 178px;
  height: 178px;
}
.avatar {
  width: 100%;

  height: 100%;
}
</style>
